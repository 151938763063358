














import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    title: String,
    link: [String, Object],
    showAll: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return {}
  },
})
