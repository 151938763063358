



























import { defineComponent } from '@nuxtjs/composition-api'
import { SwiperOptions } from 'swiper/index'

export default defineComponent({
  props: {
    title: String,
    link: String,
    showAll: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    return {}
  },
  computed: {
    sliderOpts(): SwiperOptions {
      return {
        slidesPerView: 1.2,
        centeredSlides: true,
        centeredSlidesBounds: true,
        spaceBetween: 20,
        breakpoints: {
          1200: {
            slidesPerView: 3,
            centeredSlides: false,
            spaceBetween: 0,
          },
          760: {
            spaceBetween: 0,
            slidesPerView: 2,
          },
          600: {
            slidesPerView: 2,
            centeredSlides: false,
            spaceBetween: 20,
          },
        },
      }
    },
  },
})
