


















import { defineComponent } from '@nuxtjs/composition-api'

import { useApiGetHomePage } from '~/utils/api/static-pages'
import getImageUrl from '~/utils/helpers/image-url'
import { HomeEntity, HomeLayoutToComponentType } from '~/utils/models/home.entity'
import createFromOrganizationEntityToJsonLd from '~/utils/models/schemes/organizationSchema/organizationEntityToSchema'

export default defineComponent({
  name: 'HomePage',
  components: {
    HomeBrandsBlockSection: () => import('~/components/Home/HomeBrandsBlockSection.vue'),
    HomeCategorySliderSection: () => import('~/components/Home/HomeCategorySliderSection.vue'),
    HomeFirstBlockSection: () => import('~/components/Home/HomeFirstBlockSection.vue'),
    HomeStaticBlocksSection: () => import('~/components/Home/HomeStaticBlocksSection.vue'),
    PageSectionsSeo: () => import('~/components/PageSections/PageSectionsSeo.vue'),
    HomePlaceholders: () => import('~/components/Placeholders/HomePlaceholders.vue'),
  },
  data: () => ({
    pageSeo: {
      title: '',
      description: '',
      keywords: '',
      image: null,
    },
    result: {},
    homeBrandsBlockSectionProps: {},
    homeCategorySliderSectionProps: {},
    homeFirstBlockSectionProps: {},
    homeStaticBlocksSectionProps: {},
    pageSectionsSeoProps: {},
    test: false,
    isBlockLoading: false,
  }),
  // async fetch() {
  //
  // },
  async fetch() {
    this.isBlockLoading = process.client
    const {
      exec,
      result,
      error: errorData,
    } = useApiGetHomePage({
      loading: true,
    })
    await exec({ language: this.$i18n.locale })
    if (errorData.value || !result.value) {
      this.$nuxt.error(errorData.value)
    }
    this.result = result.value as HomeEntity
    const seoData = result.value?.data?.seo_block?.[0]?.attributes || {}

    // console.log('result', result.value)

    // Todo: maybe add sort components by order
    result.value?.data?.main_page_content?.forEach((item) => {
      const { layout, attributes } = item
      // console.log('layout', layout)
      // console.log('attributes', attributes)
      // eslint-disable-next-line default-case
      switch (layout) {
        case HomeLayoutToComponentType.HomeBrandsBlockSection:
          this.homeBrandsBlockSectionProps = attributes
          break
        case HomeLayoutToComponentType.HomeCategorySliderSection:
          this.homeCategorySliderSectionProps = attributes
          break
        case HomeLayoutToComponentType.HomeFirstBlockSection:
          this.homeFirstBlockSectionProps = attributes
          break
        case HomeLayoutToComponentType.HomeStaticBlocksSection:
          this.homeStaticBlocksSectionProps = attributes
          break
        case HomeLayoutToComponentType.PageSectionsSeo:
          this.pageSectionsSeoProps = attributes
          break
      }
    })

    this.isBlockLoading = false

    this.pageSeo = {
      title: seoData.seo_title,
      description: seoData.seo_description,
      keywords: seoData.seo_keyphrases,
      image: null
    }
  },
  head() {
    const title =
      typeof this.pageSeo.title === 'string' ? this.pageSeo.title : this.$getLocaleValue(this.pageSeo.title)
    const description =
      typeof this.pageSeo.description === 'string'
        ? this.pageSeo.description
        : this.$getLocaleValue(this.pageSeo.description)

    const keywords =
      typeof this.pageSeo.keywords === 'string' ? this.pageSeo.keywords : this.$getLocaleValue(this.pageSeo.keywords)

    return {
      title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description,
        },
        {
          name: 'keywords',
          content: keywords,
        },
        {
          property: 'og:image',
          // Todo: change to config path
          content: require(`@/assets/icons/logo.svg`),
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:sitename',
          content: 'ZOO',
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: description,
        },
      ],
      // Problem with hydration if use description
      script: [
        {
          type: 'application/ld+json',
          json: createFromOrganizationEntityToJsonLd().next().value,
        },
      ],
    }
  },
  methods: {
    childMounted() {
      this.isBlockLoading = false
    },
  },
})
