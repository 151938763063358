



















































import { defineComponent } from '@nuxtjs/composition-api'
import {ProductEntity} from "~/utils/models/product.entity";
// import svgCompare from '@/assets/icons/compare.svg?inline'
export default defineComponent({
	// components: { svgCompare },
	props: {
		activeVariation: Number,
		productId: Number,
		item: {
			type: Object as () => ProductEntity,
			default: () => ({}),
		},
	},
	computed: {
		isFavourite(): boolean{
			return this.$accessor.favourite.isFavourite(this.activeVariation as number)
		},
	},

	methods: {
		addToCompare() {},
		toggleWishlist() {
			if (this.isFavourite) {
				this.$accessor.favourite.removeItem({
					id: this.activeVariation,
					removeRequest: true,
				})
			} else {
				this.$accessor.favourite.addItem({
					id: this.activeVariation as number,
					productId: this.productId as number
				} )
			}
		},
	},
})
