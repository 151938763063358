
























import { computed, defineComponent, toRefs, useContext, useRoute } from '@nuxtjs/composition-api'

import useOpen from '@/utils/compositions/useOpen'

export default defineComponent({
  props: {
    title: {
      type: Object,
      default: () => ({}),
    },
    description: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { title, description } = toRefs(props)
    const { $getLocaleValue } = useContext()
    const route = useRoute()
    const titleStr = computed(() => $getLocaleValue(title.value))
    const descStr = computed(() => $getLocaleValue(description.value))
    const { isOpen, open, close, toggle } = useOpen()
    const showBlock = computed(() => titleStr.value || descStr.value)
    const isHomePage = computed(() => route.value.fullPath === '/' || route.value.fullPath === '/ru/')
    return { isOpen, open, close, toggle, titleStr, descStr, showBlock, isHomePage }
  },
})
