















import { computed, defineComponent, ref, toRefs } from '@nuxtjs/composition-api'
import useOpen from '@/utils/compositions/useOpen'
// import { CollapseTransition } from '@ivanv/vue-collapse-transition'

export default defineComponent({
  // components: { CollapseTransition },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { items } = toRefs(props)
    const { toggle, isOpen, open, close } = useOpen()
    const hasMore = computed(() => {
      return items.value.length > 4
    })

    return { isOpen, toggle, hasMore }
  },
})
