
















import { computed, defineComponent, toRefs, useContext } from '@nuxtjs/composition-api'

export default defineComponent({
  inheritAttrs: false,
  data() {
    return {
      currentImage: '',
    }
  },
  props: {
    link: [String, Object],
    desktopImageUa: String,
    desktopImageRu: String,
    mobileImageUa: String,
    mobileImageRu: String,
    tag: {
      type: String,
      default: 'NuxtLink',
    },
  },
  methods: {
    onResize() {
      let affix = this.$i18n.locale === 'ua' ? 'Ua' : 'Ru'
      this.currentImage = this.isMobile() ? this[`mobileImage${affix}`] as string : this[`desktopImage${affix}`] as string
    },
    isMobile() {
      return window.innerWidth < 550
    }
  },
  created() {
    this.onResize()
    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
})
