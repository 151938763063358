








































import { defineComponent } from '@nuxtjs/composition-api'
import { plainToClass } from 'class-transformer'

import { useApiGetBrands } from '@/utils/api/brand'
import { BrandEntity } from '~/utils/models/brand.entity'

export default defineComponent({
  props: {
    title: String,
    description: String,
    showAll: Boolean,
    items: {
      type: Array as () => BrandEntity[],
      default: () => [],
    },
  },
  computed: {
    brands(): BrandEntity[] {
      return this.items.map<BrandEntity>((item) => plainToClass(BrandEntity, item))
    },
  },
  methods: {},
  // Todo: server cache key
  // serverCacheKey() {
  //   // Todo: can add day or month for cache by js
  //   return 'HomeCategories'
  // },
})
