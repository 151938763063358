










import { defineComponent, toRefs } from '@nuxtjs/composition-api'

import svgReviews from '@/assets/icons/set_review.svg?inline'
import { ModalName } from '~/types/modal.enum'
import { useAccessor } from '~/utils/compositions/useAccessor'
import useModal from '~/utils/compositions/useModal'

export default defineComponent({
  components: {},
  inheritAttrs: false,
  props: {
    stars: Number,
    total: Number,
    hasReviews: Boolean,
    productId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { productId } = toRefs(props)
    const accessor = useAccessor()

    const handleReviewClick = () => {
      const { showByName } = useModal()
      const modalName = accessor.user.isAuth ? ModalName.sendReview : ModalName.loginBeforeSendReview
      showByName(modalName, {
        props: {
          productId,
        },
      })
    }

    return { isAuth: accessor.user.isAuth, handleReviewClick }
  },
})
