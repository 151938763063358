import { render, staticRenderFns } from "./OffersSlider.vue?vue&type=template&id=70ababba&"
import script from "./OffersSlider.vue?vue&type=script&lang=ts&"
export * from "./OffersSlider.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TitleWithAll: require('/var/www/html/components/Base/TitleWithAll.vue').default,OfferCardImage: require('/var/www/html/components/OfferCardImage/OfferCardImage.vue').default,ASlider: require('/var/www/html/components/Base/ASlider.vue').default})
