









import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    title: Object,
    description: Object,
  },
  setup(props, { attrs }) {
    return {}
  },
})
