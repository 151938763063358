












import { defineComponent } from '@nuxtjs/composition-api'
import { SwiperOptions } from 'swiper/index'
import { useApiGetStocks } from '@/utils/api/stock'
import getImageUrl from '@/utils/helpers/image-url'
import { StockEntity } from '~/utils/models/stock.entity'
export default defineComponent({
	setup() {
		// const items = [
		// 	{
		// 		sale: '-10%',
		// 		description: 'На продукцию Royal kanin',
		// 		image: require('@/assets/img/feed_mock.png'),
		// 		color: '#C20000',
		// 		bgType: 1,
		// 		date: 'до 22 января',
		// 	},
		// 	{
		// 		sale: '-20%',
		// 		description: 'Посмотреть ассортимент',
		// 		image: require('@/assets/img/feed_mock.png'),
		// 		brand: require('@/assets/img/zoo_mock.png'),
		// 		color: '#fff',
		// 		bgType: 0,
		// 		date: 'до 22 марта',
		// 	},
		// 	{
		// 		sale: '-15%',
		// 		description: 'На продукцию Whiskas',
		// 		brand: require('@/assets/img/whiskas_mock.png'),
		// 		image: require('@/assets/img/home_offer_cat_mock.jpeg'),
		// 		color: '#872B82',
		// 		bgType: 2,
		// 		date: 'до 22 марта',
		// 	},
		// 	{
		// 		sale: '-10%',
		// 		description: 'На продукцию Royal kanin',
		// 		image: require('@/assets/img/feed_mock.png'),
		// 		color: '#C20000',
		// 		bgType: 1,
		// 		date: 'до 22 марта',
		// 	},
		// 	{
		// 		sale: '-20%',
		// 		description: 'Посмотреть ассортимент',
		// 		image: require('@/assets/img/feed_mock.png'),
		// 		color: '#fff',
		// 		bgType: 0,
		// 		date: 'до 22 марта',
		// 	},
		// 	{
		// 		sale: '-15%',
		// 		description: 'На продукцию Whiskas',
		// 		brand: require('@/assets/img/whiskas_mock.png'),
		// 		image: require('@/assets/img/home_offer_cat_mock.jpeg'),
		// 		color: '#872B82',
		// 		bgType: 2,
		// 		date: 'до 22 марта',
		// 	},
		// ]
		// return { items }
	},
	data: () => ({
		items: [],
	}),
	async fetch() {
		await this.fetchStocks()
	},
	methods: {
		async fetchStocks() {
			const { exec, error, result } = useApiGetStocks()
			await exec({})
			const items = result.value
			const normItem = items.map((item: StockEntity) => {
				const stockLayout = item.image_layout && item.image_layout[0]
				const stockData = stockLayout?.attributes
				return {
					sale: stockData.sale_count,
					title: this.$getLocaleValue(stockData.title),
					description: this.$getLocaleValue(stockData.subtitle),
					date: this.$getLocaleValue(stockData.date),
					image: getImageUrl(stockData.image),
					brand: getImageUrl(stockData.logo),
					bgType: stockData.background
						? parseInt(stockData.background)
						: 1,
					color: stockData.color,
				}
			})
			this.items = normItem
		},
	},
})
