







import { computed, defineComponent } from '@nuxtjs/composition-api'

import { useAccessor } from '~/utils/compositions/useAccessor'

export default defineComponent({
  props: {
    price: [Number, String],
    oldPrice: [Number, String],
    sale: Number,
  },
  setup(props) {
    const currency = computed(() => useAccessor().currency.symbol)
    return {
      currency,
    }
  },
})
