import useApi, { UseApiOptions } from '../compositions/useApi'
import { BrandEntity } from '../models/brand.entity'
import dataTransformer from '../transformers/data-transformer'

export const useApiGetStocks = (opts?: UseApiOptions) =>
	useApi<any, any>(
		() => ({
			method: 'GET',
			url: '/sale/all',
		}),
		opts,
		({ data }) => dataTransformer(data),
	)
