import { render, staticRenderFns } from "./ProductsSliderCollapse.vue?vue&type=template&id=edcf72c8&"
import script from "./ProductsSliderCollapse.vue?vue&type=script&lang=ts&"
export * from "./ProductsSliderCollapse.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TitleWithAll: require('/var/www/html/components/Base/TitleWithAll.vue').default,ProductsSlider: require('/var/www/html/components/Base/ProductsSlider.vue').default,ProductsCollapse: require('/var/www/html/components/Base/ProductsCollapse.vue').default})
