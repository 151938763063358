import { plainToClass } from 'class-transformer'
import useApi, { UseApiOptions } from '../compositions/useApi'
import { BrandEntity } from '../models/brand.entity'
import { ProductEntity } from '../models/product.entity'
import { DataResponse } from '../response/data.response'
import { PaginationResponse } from '../response/pagination.response'
import dataTransformer from '../transformers/data-transformer'

export const useApiGetBrands = (opts?: UseApiOptions) =>
	useApi<{ page: number }, PaginationResponse<BrandEntity>>(
		({ page }) => ({
			method: 'GET',
			url: '/brand/all',
			params: {
				page,
			},
		}),
		opts,
		dataTransformer
	)
export const useApiGetBrand = (opts?: UseApiOptions) =>
	useApi<{ slug: string }, BrandEntity>(
		({ slug }) => ({
			method: 'POST',
			url: '/brand/slug',
			data: {
				slug,
			},
		}),
		opts,
		dataTransformer
	)
export const useApiGetBrandProducts = (opts?: UseApiOptions) =>
	useApi<{ id: number; page: number }, PaginationResponse<ProductEntity>>(
		({ id, page }) => ({
			method: 'POST',
			url: '/brand/products',
			data: {
				id,
				page,
			},
		}),
		opts,
		dataTransformer
	)
export const useApiGetBrandGrouped = (opts?: UseApiOptions) =>
	useApi<any, { [key: string]: BrandEntity[] }>(
		() => ({
			method: 'GET',
			url: '/brand/grouped',
		}),
		opts,
		dataTransformer
	)
export const useApiGetBrandsValues = (opts?: UseApiOptions) =>
	useApi<any, DataResponse<any[]>>(
		() => ({
			method: 'GET',
			url: '/brand/slug-value',
		}),
		opts
	)
