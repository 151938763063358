



















import { defineComponent, ref } from '@nuxtjs/composition-api'
import { plainToClass } from 'class-transformer'

import { MenuResponse, useApiGetMenu } from '@/utils/api/menu'
import CatalogMenu from '~/mixins/CatalogMenu.vue'
import { CategoryEntity } from '~/utils/models/category.entity'
import { MenuEntity } from '~/utils/models/menu.entity'
import { MenuItemEntity } from '~/utils/models/menu-item'

export default defineComponent({
  name: 'HomeCategoriesList',
  mixins: [CatalogMenu],
  methods: {
    onMouseOut() {
      this.selectedItem = null
    },
  },
  // serverCacheKey() {
  //   // Todo: can add day or month for cache by js
  //   return 'homeCategoriesList'
  // },
})
