import { Type } from 'class-transformer'
import config from '../config/config'
import { ItemSeoEntity } from './item-seo.entity'
import { ILocaleValue } from './locale-value.entity'
export class HomeOfferSlide {
	background: string
	photo: string
  desktop_img_ua: string
  desktop_img_ru: string
  mobile_img_ua: string
  mobile_img_ru: string
	price: string
	'sale-price': string
	'shock-price': boolean
	subtitle: ILocaleValue
	title: ILocaleValue
	view_type: string
	url: string
}
export class HomeData {
	main_page_content: any[]
	seo_block: any[]
}
export class HomeEntity {
	locale: string
	id: number
	name: string
	slug: string
	path: string
	parent_id: number | null
	@Type(() => HomeData)
	data: HomeData

	template: string
	view: null
	@Type(() => ItemSeoEntity)
	seo: ItemSeoEntity
}

export enum HomeLayoutToComponentType {
  HomeCategorySliderSection = 'categories_block',
  HomeFirstBlockSection = 'home_top_slider',
  HomeBrandsBlockSection = 'brands_block',
  HomeStaticBlocksSection = 'static_blocks',
  PageSectionsSeo = 'seo-block',
}
