















import { computed, defineComponent, toRefs, useContext } from '@nuxtjs/composition-api'

import { useAccessor } from '~/utils/compositions/useAccessor'
import { HomeOfferSlide } from '~/utils/models/home.entity'

export default defineComponent({
  components: {},
  inheritAttrs: false,
  props: {
    item: {
      type: Object as () => HomeOfferSlide,
      default: () => ({}),
    },
  },
  setup(props) {
    const { item } = toRefs(props)
    const { $getLocaleValue, localePath, $config } = useContext()
    const desktopImageUa = computed(() => `${$config.imageBaseURL}/${item.value?.desktop_img_ua}`)
    const desktopImageRu = computed(() => `${$config.imageBaseURL}/${item.value?.desktop_img_ru}`)
    const mobileImageUa = computed(() => `${$config.imageBaseURL}/${item.value?.mobile_img_ua}`)
    const mobileImageRu = computed(() => `${$config.imageBaseURL}/${item.value?.mobile_img_ru}`)
    const link = computed(() => $getLocaleValue(item.value.url))
    return {
      link,
      desktopImageUa,
      desktopImageRu,
      mobileImageUa,
      mobileImageRu,
    }
  },
})
