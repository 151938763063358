import { render, staticRenderFns } from "./index.vue?vue&type=template&id=c42883ec&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeFirstBlockSection: require('/var/www/html/components/Home/HomeFirstBlockSection.vue').default,HomeCategorySliderSection: require('/var/www/html/components/Home/HomeCategorySliderSection.vue').default,HomeStaticBlocksSection: require('/var/www/html/components/Home/HomeStaticBlocksSection.vue').default,HomeBrandsBlockSection: require('/var/www/html/components/Home/HomeBrandsBlockSection.vue').default,PageSectionsSeo: require('/var/www/html/components/PageSections/PageSectionsSeo.vue').default})
