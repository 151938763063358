

















import { computed, defineComponent, toRefs } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    title: String,
    type: String,
    items: {
      type: Array,
      default: () => [],
    },
    value: [String, Number],
  },
  setup(props) {
    const { type } = toRefs(props)
    const variationComponent = computed(() => {
      if (type?.value === 'values') {
        return 'ProductCardVariationLabels'
      } else if (type?.value === 'images') {
        return 'ProductCardVariationImages'
      }
    })
    return { variationComponent }
  },
})
