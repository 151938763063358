









import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
	props: {
		new_products: Boolean,
		sale_products: Boolean,
		sales: Boolean,
		top_sale: Boolean,
	},
	setup(props, { attrs }) {
		return {}
	},
})
