






import { defineComponent } from '@nuxtjs/composition-api'
import svgDoubleArrowDown from '@/assets/icons/double_arrow_down.svg?inline'

export default defineComponent({
  components: { svgDoubleArrowDown },
  props: {
    open: Boolean,
  },
  setup() {
    return {}
  },
})
