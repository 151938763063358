

























import { computed, defineComponent } from '@nuxtjs/composition-api'

import { useAccessor } from '~/utils/compositions/useAccessor'
import { beautyPrice } from '~/utils/helpers/beauty-price'

export default defineComponent({
  props: {
    price: [Number, String],
    oldPrice: [Number, String],
    sale: [Number, String],
    showPiece: Boolean,
  },
  setup() {
    const currency = computed(() => useAccessor().currency.symbol)

    return {
      currency,
      beautyPrice,
    }
  },
})
