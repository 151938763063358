


























































import { defineComponent, onMounted, ref } from '@nuxtjs/composition-api'
import useResize from '~/utils/compositions/useResize'
export default defineComponent({
	inheritAttrs: false,
	props: {
		title: String,
		description: String,
		btn: String,
		link: [String, Object],
		image: String,
		color: String,
		sale: String,
		brand: String,
		date: String,
		bgType: {
			type: Number,
			default: 1,
		},
		tag: {
			type: String,
			default: 'NuxtLink',
		},
	},
	setup() {
		const el = ref<any>(null)
		const width = ref(0)
		const size = ref(0)
		const getSize = (k: number) => {
			const result = width.value * k
			return Math.round(result)
		}
		const calculateSize = () => {
			width.value = el.value.$el.offsetWidth
		}
		const init = () => {
			calculateSize()
			size.value = getSize(0.1)
		}
		useResize(
			() => {
				init()
			},
			{ immediate: false }
		)
		onMounted(() => {
			setTimeout(() => init(), 20)
		})
		return { el, size }
	},
})
