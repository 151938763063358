



















import { defineComponent } from '@nuxtjs/composition-api'
import { SwiperOptions } from 'swiper/index'
// @ts-ignore
import LazyHydrate from 'vue-lazy-hydration'

export default defineComponent({
  components: { LazyHydrate },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    // const items = [
    // 	{
    // 		title: 'Скучали за акциями?',
    // 		description: 'Мы знаем как поднять ему настроение! ',
    // 		image: require('@/assets/img/home_offer_dog.png'),
    // 		color: '#C20000',
    // 		oldPrice: '4 176',
    // 		price: '3 776',
    // 		bgType: 1,
    // 	},
    // ]
    // return { items }
  },
  computed: {
    sliderOpts(): SwiperOptions {
      return {
        // autoHeight: true,

        spaceBetween: 15,
        slidesPerView: 1,
        threshold: 5,

        autoplay: {
          delay: 3000,
          disableOnInteraction: true,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      }
    },
  },
})
