import { render, staticRenderFns } from "./HomeOfferSliderSlide.vue?vue&type=template&id=524440f3&"
import script from "./HomeOfferSliderSlide.vue?vue&type=script&lang=ts&"
export * from "./HomeOfferSliderSlide.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OfferImageCard: require('/var/www/html/components/OfferCard/OfferImageCard.vue').default})
