









import { defineComponent, ref, useFetch } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    sliderItems: {
      type: Array,
    },
  },
  setup() {
    return {}
  },
})
