






import { computed, defineComponent, toRefs } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    home_top_slide: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { attrs }) {
    const { home_top_slide } = toRefs(props)
    const sliderItems = computed(() => home_top_slide.value.map((slide: any) => slide.attributes))
    return { sliderItems }
  },
})
