








import { computed, defineComponent, toRefs } from '@nuxtjs/composition-api'
import { plainToClass } from 'class-transformer'
import { CategoryEntity } from '~/utils/models/category.entity'

// @ts-ignore
import LazyHydrate from 'vue-lazy-hydration'

export default defineComponent({
  components: {
    LazyHydrate,
  },
	props: {
		category: {
			type: Array,
			default: () => [],
		},
	},
	setup(props, { attrs }) {
		const { category } = toRefs(props)
		const items = computed(() =>
			category.value.map((category) =>
				plainToClass(CategoryEntity, category)
			)
		)
		return { items }
	},
})
