import { render, staticRenderFns } from "./HomeCategorySliderSection.vue?vue&type=template&id=9ac87f68&"
import script from "./HomeCategorySliderSection.vue?vue&type=script&lang=ts&"
export * from "./HomeCategorySliderSection.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeCategories: require('/var/www/html/components/Home/HomeCategories.vue').default})
