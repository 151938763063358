














import { defineComponent, toRefs } from '@nuxtjs/composition-api'

export default defineComponent({
	props: {
		items: {
			type: Array,
			default: () => [],
		},
		value: {
			type: Number,
		},
	},
	setup(props, { emit }) {
		const { value } = toRefs(props)
		const onChangeVariation = (newId: number, item: any) => {
			emit('input', newId)
		}
		return { onChangeVariation }
	},
})
