import { render, staticRenderFns } from "./ProductLabels.vue?vue&type=template&id=12938eea&"
import script from "./ProductLabels.vue?vue&type=script&lang=ts&"
export * from "./ProductLabels.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductLabel: require('/var/www/html/components/Product/ProductLabel.vue').default})
