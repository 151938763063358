










import { computed, defineComponent, useContext } from '@nuxtjs/composition-api'

import { useApiGetBrands } from '~/utils/api/brand'
import { BrandEntity } from '~/utils/models/brand.entity'

export default defineComponent({
  props: {
    brands: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    items: [] as BrandEntity[],
  }),
  async fetch() {
    await this.fetchItems()
  },
  computed: {
    brandsText(): string {
      return this.$getLocaleValue(this.brands)
    },
  },
  methods: {
    async fetchItems() {
      const { exec, error, result } = useApiGetBrands()
      await exec({ page: 1 })
      if (error.value) return
      this.items = result.value?.data as BrandEntity[]
    },
  },
})
