



















import { computed, defineComponent, PropType, toRefs } from '@nuxtjs/composition-api'

import transformProductToGtag from '~/components/ProductCard/product-card-gtag'
import { ProductEntity } from '~/utils/models/product.entity'

export default defineComponent({
  props: {
    title: String,
    link: String,
    showAll: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Array as PropType<ProductEntity[]>,
      default: () => [],
    },
    productOptions: {
      type: Object,
      default: () => ({}),
    },
    // Todo: gtag: move in props file
    gtagPrefix: {
      type: String,
      required: false,
    },
    gtagId: {
      type: [String, Number],
      required: false,
    },
    gtagName: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const { items } = toRefs(props)
    const gTagProducts =
      items.value?.length > 0
        ? items.value.map((product, index) => transformProductToGtag(product, undefined, 1, index))
        : []
    const products = computed(() => {
      if (items.value.length) return items.value
      return []
    })
    return { products, gTagProducts }
  },
  mounted() {
    this.initGTag()
  },
  methods: {
    // Todo: gtag: move methods in composition API
    validGTag() {
      return this.items?.length > 0 && this.gtagPrefix && this.gtagId && this.gtagName
    },
    runGTag(): void {
      this.$gtm.push({
        event: 'view_item_list',
        item_list_id: `${this.gtagPrefix}_${this.gtagId}`,
        item_list_name: this.gtagName,
        ecommerce: {
          items: this.gTagProducts,
        },
      })
    },
    initGTag(): void {
      if (this.validGTag()) {
        this.runGTag()
      }
    },
  },
})
