

































import { defineComponent } from '@nuxtjs/composition-api'
import { SwiperOptions } from 'swiper/index'

export default defineComponent({
  name: 'HomeCategories',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  setup() {},
  computed: {
    sliderOpts(): SwiperOptions {
      return {
        slidesPerView: 'auto',
        spaceBetween: 10,
        // centeredSlides: true,
        slidesOffsetBefore: 20,
        slidesOffsetAfter: 20,
        breakpoints: {
          1450: {
            slidesPerView: 6,
            spaceBetween: 0,
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 0,
          },
          1200: {
            slidesPerView: 5,
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 0,
          },
          992: {
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 0,
            slidesPerView: 4,
            spaceBetween: 20,
          },
          700: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          550: {
            slidesPerView: 3,
            // centeredSlides: false
          },
        },
      }
    },
  },
  // serverCacheKey() {
  //   // Todo: can add day or month for cache by js
  //   return 'HomeCategories'
  // },
})
