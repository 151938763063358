import { WithContext, PostalAddress } from 'schema-dts'

export function getAddressFromOrganizationEntityToJsonLd(): WithContext<PostalAddress> {
  return {
    '@context': 'https://schema.org',
    '@type': 'PostalAddress',
    streetAddress: 'Kyiv oblast. Kiev-Svyatoshinsky district, Sofievskaya Borshchagovka, st. Mira 40',
    addressLocality: 'Kyiv',
    postalCode: 'Postcode 08131',
    addressCountry: 'Ukraine',
  }
}
