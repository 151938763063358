import { AxiosResponse } from 'axios'
import { plainToClass } from 'class-transformer'
import useApi, { UseApiOptions } from '../compositions/useApi'
import { AttributeEntity } from '../models/attribute.entity'
import { HomeEntity } from '../models/home.entity'
import { PageEntity } from '../models/page.entity'
export const useApiGetHomePage = (opts?: UseApiOptions) =>
	useApi<{ language: string }, HomeEntity>(
		({ language }) => ({
			method: 'POST',
			// url: '/page/slug-lang',
			url: '/page/slug',
			data: {
				slug: 'home',
				language,
			},
		}),
		opts,
		({ data }: AxiosResponse<{ data: HomeEntity }>) => data.data
	)
export type SearchPageEntity = PageEntity<{
	attributes: AttributeEntity[]
	search_page_content: any[]
}>
export const useApiGetSearchPage = (opts?: UseApiOptions) =>
	useApi<{ language: string }, SearchPageEntity>(
		({ language }) => ({
			method: 'POST',
			// url: '/page/slug-lang',
			url: '/page/slug',
			data: {
				slug: 'search',
				language,
			},
		}),
		opts,
		({ data }: AxiosResponse<{ data: SearchPageEntity }>) => data.data
	)
