


















import { defineComponent } from '@nuxtjs/composition-api'

import CarouselProductsPlaceholder from '~/components/Placeholders/CarouselProductsPlaceholder.vue'
import { useApiGetProductsNew } from '~/utils/api/product'
// @ts-ignore
import LazyHydrate from 'vue-lazy-hydration'

export default defineComponent({
  components: { CarouselProductsPlaceholder, LazyHydrate },
  data: () => ({
    items: [],
    isBlockLoading: false,
  }),
  async fetch() {
    this.isBlockLoading = true
    await this.fetchItems()
  },
  methods: {
    async fetchItems() {
      const { exec, result, error } = useApiGetProductsNew()
      await exec({})
      if (error.value) return
      this.items = result.value as any
      this.isBlockLoading = false
    },
  },
})
