











import { defineComponent } from '@nuxtjs/composition-api'
import svgCircle from '@/assets/icons/offer_card_circle.svg?inline'

export default defineComponent({
  components: { svgCircle },
  props: {
    type: Number,
    color: String,
  },
  setup() {
    return {}
  },
})
