

























import { computed, defineComponent, toRefs } from '@nuxtjs/composition-api'
import { plainToClass } from 'class-transformer'
import { SwiperOptions } from 'swiper/index'

import { ProductEntity } from '~/utils/models/product.entity'

export default defineComponent({
  inheritAttrs: false,
  props: {
    title: String,
    link: String,
    showAll: Boolean,
    productOptions: {
      type: Object,
      default: () => ({}),
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { items } = toRefs(props)
    const products = computed(() => {
      if (items.value.length) return items.value
      return []
    })
    return { products }
  },
  computed: {
    sliderOpts() {
      const sliderOpts: SwiperOptions = {
        slidesPerView: 2,
        spaceBetween: 8,
        simulateTouch: false,
        breakpoints: {
          1200: {
            slidesPerView: 5,
          },
          900: {
            slidesPerView: 4,
            spaceBetween: 15,
          },
          650: {
            slidesPerView: 3,
          },
        },
      }
      return sliderOpts
    },
  },
})
