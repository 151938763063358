






import { defineComponent } from '@nuxtjs/composition-api'
enum ITypes {
  green = 'green',
  yellow = 'yellow',
  red = 'red',
}
export default defineComponent({
  props: {
    text: String,
    type: {
      type: String as () => ITypes,
    },
  },
  setup() {
    return {}
  },
})
