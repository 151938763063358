import { render, staticRenderFns } from "./HomeStaticBlocksSection.vue?vue&type=template&id=0a59d4e4&"
import script from "./HomeStaticBlocksSection.vue?vue&type=script&lang=ts&"
export * from "./HomeStaticBlocksSection.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SliderProductsStock: require('/var/www/html/components/Slider/SliderProductsStock.vue').default,HomeStocksSlider: require('/var/www/html/components/Home/HomeStocksSlider/HomeStocksSlider.vue').default,SliderProductsHits: require('/var/www/html/components/Slider/SliderProductsHits.vue').default,SliderProductsNew: require('/var/www/html/components/Slider/SliderProductsNew.vue').default})
