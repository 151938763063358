import { getAddressFromOrganizationEntityToJsonLd } from '~/utils/models/schemes/organizationSchema/organizationAddressSchema/orgranizationAddressEntityToSchema'

export default function* createFromOrganizationEntityToJsonLd(): any {
  yield {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: 'https://zoo.com.ua',
    logo: 'https://zoo.com.ua/_nuxt/assets/icons/logo.svg',
    // description: getLocaleValueCallback(organizationData?.description),
    address: getAddressFromOrganizationEntityToJsonLd(),
    telephone: '044-333-73-73, 098-533-73-73',
    email: 'zoo@zoo.com.ua',
  }
}
