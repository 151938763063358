














































import { defineComponent } from '@nuxtjs/composition-api'
import { SwiperOptions } from 'swiper/index'

import svgArrowLeft from '@/assets/icons/arrow_left.svg?inline'
import svgArrowRight from '@/assets/icons/arrow_right.svg?inline'

export default defineComponent({
  components: { svgArrowLeft, svgArrowRight },
  props: {
    title: String,
    sliderOptions: {
      type: Object,
      default: () => ({}),
    },
    slideClass: {
      type: [Object, Array, String],
    },
    sliderClass: {
      type: [Object, Array, String],
    },
    items: {
      type: Array,
      default: () => [],
    },
    arrowPrevClass: {
      type: [Array, String, Object],
    },
    arrowNextClass: {
      type: [Array, String, Object],
    },
    titleClass: {
      type: [Array, String, Object],
    },
  },
  computed: {
    sliderOpts(): SwiperOptions {
      const sliderOpts: SwiperOptions = {
        threshold: 5,
        navigation: {
          nextEl: `#swiper-button-next-${this._uid}`,
          prevEl: `#swiper-button-prev-${this._uid}`,
        },
      }
      return { ...sliderOpts, ...this.sliderOptions }
    },
  },
})
