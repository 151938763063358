


















import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    value: Number,
  },
  setup(props, { emit }) {
    const items = [
      {
        name: '1',
        value: 1,
      },
      {
        name: '24',
        sale: 28,
        value: 24,
      },
    ]
    const chooseItem = (newValue: number) => {
      emit('input', newValue)
    }
    return { items, chooseItem }
  },
})
