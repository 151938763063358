import { render, staticRenderFns } from "./ProductsCollapse.vue?vue&type=template&id=5ac5a177&"
import script from "./ProductsCollapse.vue?vue&type=script&lang=ts&"
export * from "./ProductsCollapse.vue?vue&type=script&lang=ts&"
import style0 from "./ProductsCollapse.vue?vue&type=style&index=0&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductCard: require('/var/www/html/components/ProductCard/ProductCard.vue').default,ArrowCollapse: require('/var/www/html/components/Base/ArrowCollapse.vue').default})
