import { render, staticRenderFns } from "./HomeFirstBlock.vue?vue&type=template&id=2bca27f6&"
import script from "./HomeFirstBlock.vue?vue&type=script&lang=ts&"
export * from "./HomeFirstBlock.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeCategoriesList: require('/var/www/html/components/Home/HomeCategoriesList.vue').default,HomeOfferSlider: require('/var/www/html/components/Home/HomeOfferSlider/HomeOfferSlider.vue').default})
