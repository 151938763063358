import { render, staticRenderFns } from "./HomeOfferSlider.vue?vue&type=template&id=12e62be0&"
import script from "./HomeOfferSlider.vue?vue&type=script&lang=ts&"
export * from "./HomeOfferSlider.vue?vue&type=script&lang=ts&"
import style0 from "./HomeOfferSlider.vue?vue&type=style&index=0&lang=postcss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeOfferSliderSlide: require('/var/www/html/components/Home/HomeOfferSlider/HomeOfferSliderSlide.vue').default})
