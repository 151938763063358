








import {
  computed,
  defineComponent,
  toRefs,
  useContext,
} from '@nuxtjs/composition-api'
import { ProductLabels } from '@/types/constants'
export default defineComponent({
  props: {
    // topSale: Boolean,
    // isPopular: Boolean,
  },
  setup(props) {},
})
