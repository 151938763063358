



















import { defineComponent } from '@nuxtjs/composition-api'
import useOpen from '~/utils/compositions/useOpen'

export default defineComponent({
  props: {
    height: String,
    hasMore: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { toggle, isOpen, open, close } = useOpen()

    return { open, toggle, isOpen }
  },
})
