





























import { computed, defineComponent, toRefs, useContext } from '@nuxtjs/composition-api'

import svgCheckMark from '@/assets/icons/checkmark.svg?inline'
import svgCross from '@/assets/icons/cross.svg?inline'
import { AvailableStatus } from '~/utils/models/product.entity'

export default defineComponent({
  components: { svgCheckMark, svgCross },
  props: {
    // 0 - no, 1 - has, 2 - заканчивается
    status: {
      type: String,
      default: AvailableStatus.available,
    },
    cnt: Number,
  },
  setup(props) {
    const { status } = toRefs(props)
    const { i18n } = useContext()
    const text = computed(() => {
      if (status.value === AvailableStatus.available) {
        return i18n.t('available')
      }
      if (status.value === AvailableStatus.gettingOut) {
        return i18n.t('ends')
      }
      return i18n.t('notAvailable')
    })
    const isAvailable = computed(() => status.value === AvailableStatus.available)
    const isGettingOut = computed(() => status.value === AvailableStatus.gettingOut)
    return { text, AvailableStatus, isAvailable, isGettingOut }
  },
})
